.container{
  display: flex; flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #eeeeee;
  .banner{
    font-size: 25px;
    font-weight: bold;
  }
}


.containerModal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .modalBox {
    width: 100%;
    border-radius: 10px;
    background-color: white;
    display: flex;
    padding: 10px;
    flex-direction: column;

    .titleContainer {
      color: black;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #e0dddd;
      padding: 10px 0;
      font-weight: bold;
    }

    .bottomModal {
      display: inline-block;
      padding: 10px 0;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      color: black;

      .addStyle {
        color: black;
        font-weight: bold;
        border: 1px solid black;
        border-radius: 4px;
        height: 10px;
        padding: 0 3px;
        margin-right: 5px;
      }

      .contentContainer {
        display: flex;
        flex-direction: column;

        .openAlert {
          margin-top: 10px;
          border: 1px solid #d0d0d0;
          background-color: white;
          border-radius: 8px;
          padding: 10px 15px;
          font-weight: 600;
          color: #1a1919;
          font-size: 15px;

        }
      }

    }
  }
}


.container{
  font-size: 25px;
  display: flex;
  align-items: start;
  padding-top: 50px;
  justify-content: center;

  .banner{
    width: 100%;
    border-bottom: 1px solid #9f9a9a;
    padding-bottom: 20px;
      display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
